<template>
  <div>
    <loading :active.sync="isLoading" 
      :can-cancel="false" 
      :color="'#aa945b'"
      :is-full-page="fullPage">
    </loading>
    <CCard>
      <CCardHeader>
        <CModal
          title="Info"
          color="info"
          centered
          :show.sync="downloadAnalyticsModal"
        >
          {{messageAnalyticsModal}}
          <template slot="footer">
            <CButton color="info"  @click="downloadAnalyticsModal = false" >OK</CButton>
          </template>
        </CModal>
        <b>Questions Summary</b>
        <div class="card-header-actions">
          <CButton style="background-color:#998542; color:white" @click="exportAnalytics">Export to CSV</CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="6" v-for ="(value, index) in years_filter" :key="index">
            <label><b>  {{value.name}}</b></label>
            <select class="form-control mb-3" style="max-width:100%" v-model="value.selected_filter" @change="changeYearFilter">
              <template v-for="option in value.items">
                <option :value='option'>{{option.name}}</option>
              </template>
            </select>
          </CCol>
          <CCol sm="3">
              <label style="color:green"><b>Total </b></label>
              <CInput v-model="data.questions" disabled/>
          </CCol>
          <CCol sm="3">
            <label style="color:red"><b>Untagged </b></label>
            <CInput v-model="data.untagged" disabled/>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
              <zk-table
                ref="table"
                sum-text="sum"
                index-text="#"
                :data="data.children"
                :empty-text	="'No Data'"
                :columns="columns"
                :stripe="props.stripe"
                :border="props.border"
                :show-header="props.showHeader"
                :show-summary="props.showSummary"
                :show-row-hover="props.showRowHover"
                :show-index="props.showIndex"
                :tree-type="props.treeType"
                :is-fold="props.isFold"
                :expand-type="props.expandType"
                :selection-type="props.selectionType">
              </zk-table>
          </CCol>
          <CCol sm="6">
            <CChartBar
                :datasets="chart_datasets"
                :labels = "['English', 'Maths', 'Science']"
              />
            <h4 style="text-align: center;"> Questions Overview - Chart</h4>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <b>Within Subjects</b>
      </CCardHeader>
      <CCardBody>
        <CRow >
          <CCol sm="6" v-for ="(value, index) in tag_filters" :key="index">
            <label><b>  {{value.name}}</b></label>
            <select class="form-control mb-3" style="max-width:100%" v-model="value.selected_filter"  @change="changeTagFilter(value.selected_filter)">
              <template v-for="option in value.items">
                <option :value='option'>{{option.name}}</option>
              </template>
            </select>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6" v-for="(value, name) in tag_data" :key="name" class="mb-4">
            <CCard>
              <CCardHeader>
                <div>
                    <b>{{name}}</b>
                </div>
              </CCardHeader>
              <CCardBody>
                  <CRow class="mb-3">
                      <CCol sm="6">
                          <h6 style="color:green">Total : {{value.questions}}</h6>
                      </CCol>
                      <CCol sm="6" >
                          <h6 style="color:red" class="float-right">Untagged : {{value.untagged}}</h6>
                      </CCol>
                  </CRow>
                  <CDataTable 
                    :items="value.children"
                    :noItemsView='{ noResults: "No Results Found", noItems: "No Results Found" }'
                    hover
                    responsive
                    striped
                    sorter
                    style="overflow-y: auto; max-height: 300px;"
                    >
                  </CDataTable>
              </CCardBody>
            </CCard>

          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

  </div>
</template>

<script>
import MainChartExample from './charts/MainChartExample'
import WidgetsDropdown from './widgets/WidgetsDropdown'
import WidgetsBrand from './widgets/WidgetsBrand'
import Vue from 'vue'
import ZkTable from 'vue-table-with-tree-grid'
import { CChartBar } from '@coreui/vue-chartjs'
import { mapState } from "vuex";


Vue.component(ZkTable.name, ZkTable)
export default {
  name: 'Dashboard',
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
    CChartBar
    
    //ZkTable
  },
   computed: {
    ...mapState(["analytics"]),
    ...mapState({
      data: state => state.analytics.data,
      years_filter: state => state.analytics.filters,
      tag_data: state => state.analytics.tag_data,
      tag_filters: state => state.analytics.tag_filters
    }),

  },
  data () {
    return {
        isLoading:false,
        downloadAnalyticsModal:false,
        messageAnalyticsModal:null,
        fullPage:true,
        selected_year:null,
        chart_datasets_test:[],
          props: {
          stripe: false,
          border: false,
          showHeader: true,
          showSummary: false,
          showRowHover: true,
          showIndex: false,
          treeType: true,
          isFold: false,
          expandType: false,
          selectionType: false
        },
        chart_datasets:[],
        columns: [
          {
            label: 'Subjects',
            prop: 'name',
            width: '300px',
          },
          {
            label: 'Questions',
            prop: 'questions',
          }
        ],     
      selected: 'Month',
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchAnalytics();
    await this.fetchAnalyticsTags();
    await this.getChartData(this.years_filter, this.data)

    this.isLoading = false;
  },
  methods: {
      // On Change Year Filter For Questions Summary
      async changeYearFilter(){
        
        // Reload the Analutics Data
        await this.fetchAnalytics();
        await this.getChartData(this.years_filter, this.data)


      },
      async changeTagFilter(selected_filter){
        await this.fetchAnalyticsTags();
      },
      getSelectedYear(filter){
        let selected_year = null

        for(let i = 0; i < filter.length; i++){
          selected_year = filter[i].selected_filter
        }

        return selected_year
      },
      async fetchAnalyticsTags() {
            this.isLoading = true;
            //var stringFilter = encodeURIComponent(JSON.stringify(this.getFilters()));
            await this.$store.dispatch("analytics/fetchTags", {
                    // query: this.$route.query,
                    filter: this.getTagFilters()
                });

            this.isLoading = false;
      },
      getTagFilters(){
        var obj = {}; 

        for(let i = 0; i < this.tag_filters.length; i++){
            obj[this.tag_filters[i].name] = [this.tag_filters[i].selected_filter]; 
        }
        return obj;
      },
      async fetchAnalytics() {
            this.isLoading = true;
            //var stringFilter = encodeURIComponent(JSON.stringify(this.getFilters()));
            await this.$store.dispatch("analytics/fetch", {
                    // query: this.$route.query,
                    filter: this.getFilters()
                });

            this.isLoading = false;
      },
      async exportAnalytics() {
            this.isLoading = true;
            const response = await this.$store.dispatch("analytics/export");
            console.log("exportAnalytics",response);
            this.isLoading = false;
            this.downloadAnalyticsModal = true;
            this.messageAnalyticsModal = response.data;
      },
      async getChartData(filter, data){
        let selected_year = null;
        let datasets = []

        // Get the selected filters from the array

        selected_year = await this.getSelectedYear(filter)
        if(selected_year){
            if(selected_year.name === 'All Years'){

              let e_count = 0;
              let m_count = 0;
              let s_count = 0;

              for(let i = 0; i < data.children.length; i++){
                let y_data = data.children[i]
              
                for(let j = 0; j < y_data.children.length; j++){
                  if(y_data.children[j].name == "English"){

                    e_count = e_count + (y_data.children[j].questions)
                  }
                  else if(y_data.children[j].name == "Science"){
                    s_count = s_count + (y_data.children[j].questions)
                  } else if (y_data.children[j].name == "Mathematics"){
                    m_count = m_count + (y_data.children[j].questions)
                  }
                }
              }

              datasets = [
                {
                  label:"All Years", 
                  backgroundColor: '#98FB98',
                  borderColor: '#006400',
                  borderWidth: 1,
                  hoverBackgroundColor: '#98FB98',
                  hoverBorderColor: '#006400',
                  data: [e_count, m_count, s_count, 0]}
              ]

              this.chart_datasets = datasets

            }
            else{
             
                if(data.children){
                    let subjects = data.children[0].children
                    let eng =  subjects.filter(obj => {
                        return obj.name == "English";
                      })
                    let math =  subjects.filter(obj => {
                        return obj.name == "Mathematics";
                    })
                    let sci =  subjects.filter(obj => {
                        return obj.name == "Science";
                    })
                    datasets = [
                        {
                          label:data.children[0].name , 
                          backgroundColor: '#98FB98',
                          borderColor: '#006400',
                          borderWidth: 1,
                          hoverBackgroundColor: '#98FB98',
                          hoverBorderColor: '#006400',
                          data: [eng[0].questions, math[0].questions, sci[0].questions, 0]}
                    ]
                    
                    this.chart_datasets = datasets

                }

            }
        }

      },
      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      },
      getFilters(){
        var obj = {}; 

        for(let i = 0; i < this.years_filter.length; i++){
          obj[this.years_filter[i].name] = [this.years_filter[i].selected_filter]; 
        }
        
        return obj;
      },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  }
}
</script>
